body {
  font-family: "Rajdhani", sans-serif !important;
  font-weight: 500 !important;
  color: #333 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold !important;
  margin-bottom: 20px !important;
}

.header {
  padding: 10px 0px;
  box-shadow: 0px 10px 10px #0000000d;
  /* position: fixed;
  width: 100%; */
  position: sticky;
}

.ptb-5 {
  padding: 50px 0px;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* min-height: 90px; */
}
.navbar-menu-container {
  display: none;
}
.navbar-links-container a {
  margin-left: 3rem;
  text-decoration: none;
  color: #333 !important;
  font-size: 15px;
}

.navbar-links-container a.active {
  color: #11998e !important;
  font-weight: 700;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar:vertical {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #11998e;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #555;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c82b60;
}

@keyframes floatAnimation {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.themebtn {
  display: none;
}

.floating-item {
  animation: floatAnimation 3s ease-in-out infinite;
}


@media (max-width: 800px) {
  .nav-logo-container {
    max-width: 140px;
  }
  .navbar-links-container {
    display: none;
  }
  .navbar-menu-container {
    display: flex;
    font-size: 26px;
  }
  .home-bannerImage-container {
    max-width: 450px;
  }

  .mobcenter {
    text-align: center !important;
  }

  .mobnone {
    display: none;
  }

  .mobdarktext {
    color: #333 !important;
  }

  .whychooseus {
    padding: 80px 0px 0px !important;
    background: #333;
    background-image: none;
  }

  .banner1 {
    background: #e0f2f1;
    padding-bottom: 20px;
  }

  .banner {
    background: #11998e;
    background-image: none !important;
  }

  .mobpb {
    padding-bottom: 3rem !important;
  }
}
