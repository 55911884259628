.nav-logo-container {
  img {
    width: 80%;
  }
}

.banner {
  background-image: url("../img/home/shape1.svg");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  .cardnone {
    background-color: transparent;
    padding: 0px;
    color: #fff;
    flex-flow: row-reverse !important;
  }
}

.whychooseus {
  background-image: url("../img/home/shape2.svg");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  padding: 300px 0px 0px;
}

.btn-primary {
  background-image: linear-gradient(45deg, #129b8d, #38ef7d);
  color: #fff !important;
  text-decoration: none;
  padding: 12px 20px;
  border-radius: 10px;
}

.primary-text {
  color: #11998e;
}

.ptb-7 {
  padding: 70px 0px;
}

.footer {
  background-color: #FBFBFB;
}

a {
  color: #11998e !important;
  text-decoration: none !important;
}

.social-icons {
  a {
    margin-left: 10px;
    img {
      width: 24px;
    }
  }
}

.themebtn {
  // position: fixed;
  // top: 50%;
  // right: 0;
  // transform: rotate(-90deg);
  padding: 5px !important;
  margin-left: 40px;
  background-color: #f8f8f8 !important;
  border: 0;
  border-radius: 5px;
  border: 1px solid transparent;
  svg {
    font-size: 24px;
  }
  &:hover {
    background-color: #000 !important;
    border: 1px solid #fff;
    transition: 0.6s ease;
    svg {
      color: #fff;
    }
  }
}